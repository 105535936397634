<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="850px"
      transition="dialog-transition"
    >
      <v-card class="pa-4" tile>
        <div v-if="loadingContent" class="text-center pa-4">
          <div class="text-subtitle-1 grey--text font-weight-normal">
            Carregando...
          </div>
          <v-progress-circular
            size="45"
            indeterminate
            class="ma-5"
          ></v-progress-circular>
        </div>
        <div v-if="!loadingContent">
          <div class="header d-grid grid-col-1-auto pa-2">
            <div
              class="text-h6 font-weight-bold grey--text text--darken-3 text-uppercase"
            >
              Detalhes do Pagamento
            </div>
            <div>
              <v-btn text @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="dados.status == 'AUTORIZADO'" class="py-4 mb-2">
            <v-row
              v-if="!confirma_pagamento_toggle && !recusa_pagamento_toggle"
              class="text-center"
            >
              <v-col cols="6">
                <v-btn
                  color="red darken-1"
                  depressed
                  dark
                  @click="recusarPagamento()"
                  >Recusar pagamento</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  depressed
                  dark
                  color="primary"
                  @click="confirmPagamento()"
                  >Confirmar pagamento</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div
            v-if="recusa_pagamento_toggle"
            class="pa-3 red lighten-5 rounded-lg"
          >
            <div class="pa-3 text-center">
              Tem certeza que deseja <strong>recusar</strong> esse pagamento ?
            </div>
            <div class="pa-3">
              <v-row class="text-center">
                <v-col cols="6">
                  <v-btn text color="red" @click="closeConfirms()"
                    >CANCELAR</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn text color="primary" @click="recusarPagamentoFinal()"
                    >CONFIRMAR</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
          <div
            v-if="confirma_pagamento_toggle"
            class="pa-3 red lighten-5 rounded-lg"
          >
            <div class="pa-3 text-center">
              Tem certeza que deseja <strong>confirmar</strong> esse pagamento ?
            </div>
            <div class="pa-3">
              <v-row class="text-center">
                <v-col cols="6">
                  <v-btn text color="red" @click="closeConfirms()"
                    >CANCELAR</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn text color="primary" @click="confirmPagamentoFinal()"
                    >CONFIRMAR</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="pa-1 text-body-1">
            <div class="mb-2">
              <div
                class="rounded text-body-1 font-weight-normal pa-3 grey--text text--darken-1 grey lighten-4 d-inline-block"
              >
                <span class="font-weight-bold"> ID DO EVENTO:</span>
                {{ dados.turmaId }}
              </div>
              <div
                class="rounded text-body-1 font-weight-normal pa-3 grey--text text--darken-1 grey lighten-4 d-inline-block ml-0 ml-sm-2 mt-2 mt-sm-0"
              >
                <span class="font-weight-bold">NOME DO EVENTO: </span
                >{{ dados.nomeTurma }}
              </div>
            </div>

            <v-card class="pa-4 blue-grey lighten-5" flat>
              <div
                class="font-weight-light text-uppercase blue-grey--text text--lighten-1"
              >
                Descrição da Solicitação
              </div>
              <div class="blue-grey--text text--darken-2 text-body-1">
                {{ dados.descricao | temRegistro }}
              </div>
            </v-card>
            <div v-if="dados.fornecedor">
              <div
                class="py-3 text-h6 font-weight-light blue-grey--text darken-2"
              >
                Fornecedor
              </div>
              <div class="px-5 py-1">
                <v-row class="text-left">
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">
                      PRESTADOR / EMPRESA
                    </div>
                    {{ dados.fornecedor.nome }}
                  </v-col>
                  <v-col cols="6"> </v-col>
                </v-row>
                <v-row class="text-left">
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">Telefone</div>
                    {{ dados.fornecedor.telefone | temRegistro }}
                  </v-col>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">Email</div>
                    {{ dados.fornecedor.email | temRegistro }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="font-weight-bold text-uppercase">CPF/CNPJ</div>
                    {{ dados.fornecedor.cpfCnpj }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card class="pa-4 ma-n1 grey lighten-5" flat>
                      <div class="font-weight-bold text-uppercase">
                        Descrição do Fornecedor
                      </div>
                      <div>{{ dados.fornecedor.descricao | temRegistro }}</div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="dados.fornecedor">
              <div
                class="pa-1 mt-3 font-weight-light blue-grey--text darken-2 text-h6"
              >
                Dados bancários
              </div>
              <div class="px-5 py-2">
                <v-row>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">Banco</div>
                    <div>{{ dados.fornecedor.banco | temRegistro }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">Cod.Banco</div>
                    <div>{{ dados.fornecedor.codigoBanco | temRegistro }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">Agencia</div>
                    <div>{{ dados.fornecedor.agencia | temRegistro }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">
                      Conta - digito
                    </div>
                    <div>
                      {{ dados.fornecedor.conta | temRegistro }} -
                      {{ dados.fornecedor.contaDigito | temRegistro }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="font-weight-bold text-uppercase">
                      Titular da Conta
                    </div>
                    {{ dados.fornecedor.titularConta }}
                  </v-col>
                  <v-col cols="6"></v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div class="pa-2">
            <div
              class="pa-1 mt-3 font-weight-light blue-grey--text darken-2 text-h6"
            >
              Anexos
            </div>
            <v-btn
              :loading="loadingAnexo"
              color="grey lighten-3"
              depressed
              @click="selectInputFile()"
            >
              <v-icon>
                mdi-paperclip
              </v-icon>
              Enviar Anexo
            </v-btn>
            <input
              v-show="false"
              id="anexoFile"
              type="file"
              @change="selectDoc()"
            />
          </div>
          <div id="anexos" class="pa-2">
            <v-card
              v-if="confirmDeleteAnexo"
              class="elevation-0 red lighten-5 pa-3 py-5 text-center"
            >
              <div>
                <div class="py-3">
                  Tem certeza que deseja DELETAR o arquivo?
                </div>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      text
                      color="primary"
                      @click="confirmDeleteAnexo = false"
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn text color="red" @click="requestDeleteAnexo()"
                      >Sim, deletar arquivo</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card
              v-for="(file, index) in dados.anexos"
              :key="index"
              class="pa-2 my-1 justify-center align-center verticalCenter elevation-0 borderSafe"
            >
              <div class="d-grid grid-col-auto-1-auto">
                <div class="text-center">
                  <v-btn text color="primary" @click="openFile(file)">
                    <v-icon size="35">
                      mdi-download-box
                    </v-icon>
                  </v-btn>
                </div>
                <div
                  class="text-body-2 pa-2 text-left text-truncate"
                  :title="file.nome"
                >
                  {{ file.nome }}
                </div>
                <div>
                  <v-btn text color="red darken-1" @click="deleteFile(file.id)">
                    <v-icon size="25">
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
          <div class="comentarios pa-1">
            <div
              class="text-h6 font-weight-light blue-grey--text text--lighten-2"
            >
              Comentários
            </div>
            <div class="py-2">
              <div class="d-grid grid-col-1-auto text-center ">
                <v-row class="align-center">
                  <v-col cols="12" sm="9" md="10">
                    <input
                      v-model="mensagemPayload"
                      class="pa-3 grey lighten-4 rounded full-width"
                      placeholder="Novo comentário"
                      @keyup.enter="enviarMensagem()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="2"
                    class="text-center text-sm-left"
                  >
                    <v-btn
                      depressed
                      large
                      color="primary"
                      @click="enviarMensagem()"
                      >Enviar</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
            <div id="listaComments">
              <div
                v-for="(msg, index) in dados.comentarios"
                :key="index"
                class="pa-3 grey lighten-5 mt-1 rounded-lg"
              >
                <v-row justify="center" class="align-center text-center">
                  <v-col cols="2">
                    <v-avatar>
                      <v-img :src="msg.fotoUrl"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10" class="text-left">
                    <div class="font-weight-bold text-subtitle-2">
                      {{ msg.autor }}
                      <div
                        class="font-weight-light text-body-2 text-uppercase d-inline-block"
                      >
                        {{ msg.dataComentario | momentFilter }}
                      </div>
                    </div>

                    <div>{{ msg.comentario }}</div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceSolicitacoes from "@/services/solicitacoes";
import moment from "moment";
export default {
  filters: {
    momentFilter(val) {
      return moment(val).format("DD/MM/YYYY - hh:mm:ss ");
    },
    temRegistro(val) {
      if (val) return val;
      else return "S/ REGISTRO";
    }
  },
  props: {
    uid: {
      type: Number,
      required: true
    },
    directUpload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirma_pagamento_toggle: false,
      recusa_pagamento_toggle: false,
      loadingContent: true,
      loadingAnexo: false,
      fileAnexo: "",
      dialog: true,
      mensagemPayload: "",
      idToDeleteAnexo: "",
      confirmDeleteAnexo: false,
      dados: {}
    };
  },
  mounted() {
    this.getSolicitacaoById();
  },
  methods: {
    requestDeleteAnexo() {
      serviceSolicitacoes
        .removerAnexo(this.dados.id, this.idToDeleteAnexo)
        .then(resp => {
          this.$root.showInfo(resp.data);
          this.getSolicitacaoById();
        })
        .catch(err => {
          this.$root.showError(err);
        })
        .finally(() => {
          this.confirmDeleteAnexo = false;
        });
    },
    deleteFile(idAnexo) {
      this.idToDeleteAnexo = idAnexo;
      this.confirmDeleteAnexo = true;
    },
    enviarMensagem() {
      //beta-tester
      if (this.mensagemPayload) {
        let mensagem = this.mensagemPayload;
        serviceSolicitacoes
          .setComentario(this.dados.id, mensagem)
          .then(() => {
            this.getSolicitacaoById();
          })
          .catch(err => {
            this.$root.showError(err.data);
          })
          .finally(() => {
            this.mensagemPayload = "";
          });
      } else {
        return false;
      }
    },
    openFile(file) {
      window.open(file.url, file.nome);
    },
    getSolicitacaoById() {
      serviceSolicitacoes
        .getSolicitacaoPorID(this.uid)
        .then(resp => {
          this.dados = resp.data;
          this.loadingContent = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    selectInputFile() {
      document.querySelector("#anexoFile").click();
    },
    selectDoc() {
      let makefile = document.querySelector("#anexoFile").files[0];
      let formData = new FormData();
      formData.append("file", makefile);
      formData.append("nome", makefile.name);
      this.fileAnexo = formData;
      this.uploadFile();
    },
    uploadFile() {
      let solicitacaoID = this.dados.id;
      let file = this.fileAnexo;
      serviceSolicitacoes
        .enviarAnexo(solicitacaoID, file)
        .then(resp => {
          console.log(resp);
          this.getSolicitacaoById();
          this.$root.showInfo("Enviado com sucesso!");
          this.$emit("atualiza");
        })
        .catch(err => {
          console.log(err);
        });
    },
    closeConfirms() {
      this.confirma_pagamento_toggle = false;
      this.recusa_pagamento_toggle = false;
    },
    recusarPagamentoFinal() {
      serviceSolicitacoes.setSolicitacaoRecusada(this.dados.id).then(resp => {
        console.log(resp);
        this.$root.showInfo(resp.data.body);
        this.$emit("atualiza");
        this.$emit("close");
        this.recusa_pagamento_toggle = false;
      });
    },
    recusarPagamento() {
      this.recusa_pagamento_toggle = true;
    },
    confirmPagamentoFinal() {
      serviceSolicitacoes
        .setSolicitacaoPaga(this.dados.id)
        .then(resp => {
          this.$root.showInfo(resp.data.body);
          this.$emit("atualiza");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.showInfo(err.data);
          console.log("erro" + err.data);
          this.closeConfirms();
        });
    },
    confirmPagamento() {
      this.confirma_pagamento_toggle = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.heightTextArea {
  background-color: rgba(150, 150, 150, 0.1);
  border: none;
  border-radius: 4px;
  height: 200px;
}
</style>
